.input {
    width: 40%;
    border-radius: 8px;
    border: 2px solid var(--color-highlight);
    padding: 10px 15px;

    &:focus {
        outline: none;
    }
}

.input,
button {
    box-shadow: 1px 3px 9px #0000003a !important;
}

.search-box {
    max-width: 500px;
}