h3 {
    width: fit-content;
    margin-bottom: 16px;
}

p {
    width: fit-content;
    margin: 0;
}

.card-wrapper {
    max-width: 270px;
    text-decoration: none;
}

.card {
    border: 2px solid var(--color-highlight);
    border-radius: 10px;
    box-sizing: border-box;
    box-shadow: 1px 3px 9px #0000003a;

    &:hover {
        cursor: pointer;
        box-shadow: 1px 3px 9px #00000082;
    }

    &,
    & img {
        overflow: hidden;
    }
}

.badge {
    top: 10px;
    right: 10px;
    font-size: 15px;
}

.card-detail-info {
    border: 0px solid black;
}

.info-wrapper {
    gap: 4rem;
    @media (max-width: 700px) {
        flex-direction: column-reverse;
        text-align: center;
        align-items: center;
        gap: 2rem;
    }
}
